/* eslint-disable no-restricted-imports */

import version from './config.json';

// Do not change these values, use a .env file instead. An example file has been supplied.

const base = new URL(import.meta.env.VITE_SERVER_URL_BASE ?? 'https://caretakerapientra.azurewebsites.net');
const noTopDomain = /(.*)\./.exec(base.hostname)?.[1];

export const NAME = noTopDomain;

export const VERSION = version.VERSION;
export const BUILD = import.meta.env.VITE_BUILD_NUMBER ?? 'no number'; //version.BUILD;
export const BUILD_TIME = version.BUILD_TIME;
export const FULL_VERSION = `${VERSION}.${BUILD}`;

export const SERVER_URL_BASE = base.toString().replace(/\/$/, '');
export const SERVER_URL = base.toString().replace(/\/$/, '') + '/api';
export const DASHBOARD_URL = import.meta.env.VITE_DASHBOARD_URL ?? SERVER_URL + '/dashboard';

export const AUTH_SERVER_URL_BASE =
    import.meta.env.VITE_AUTH_SERVER_URL_BASE ?? 'https://caretaker-nextauth.azurewebsites.net';
export const NODE_TEST_API = import.meta.env.VITE_NODE_TEST_API ?? 'https://reactnode.caretaker.dk';

// Customer overwrite
export const CUSTOMER_LOGO_URL = import.meta.env.VITE_CUSTOMER_LOGO_URL ?? '/images/ct-logo-text-scale.svg';

/** Show MSFT sign-in button */
export const UI_SIGNIN_SHOW_MSFT_SIGNIN = import.meta.env.VITE_UI_SIGNIN_SHOW_MSFT_SIGNIN !== 'false';

/** Show Caretaker's custom sign-in button */
export const UI_SIGNIN_SHOW_CT_SIGNIN = import.meta.env.VITE_UI_SIGNIN_SHOW_CT_SIGNIN !== 'false';

// MS Auth options
export const MSAL_CLIENT_ID = import.meta.env.VITE_MSAL_CLIENT_ID ?? '03d0cec3-56bd-471c-9b6c-53857de616eb';
export const MSAL_API_ID = import.meta.env.VITE_MSAL_API_ID ?? 'cfe93624-dadb-4ae0-b22e-20032d8aa515';
export const MSAL_AUTHORITY = import.meta.env.VITE_MSAL_AUTHORITY ?? 'https://login.microsoftonline.com/common';
export const MSAL_REDIRECT_URI = import.meta.env.VITE_MSAL_REDIRECT_URI ?? window.location.origin + "/blank.html";

const config = {
    NAME,

    VERSION,
    BUILD,
    BUILD_TIME,
    FULL_VERSION,

    SERVER_URL_BASE,
    SERVER_URL,
    NODE_TEST_API,
    DASHBOARD_URL,

    AUTH_SERVER_URL_BASE,
} as const;

export default config;
